import { NotFound, PrivateRoute } from 'components/commons';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { TrueFalse } from 'types/common';

const AuthPage = React.lazy(() => import('features/auth/pages/AuthPage'));
const LoginPage = React.lazy(() => import('features/auth/pages/Login'));
const ForgotPage = React.lazy(() => import('features/auth/pages/ForgotPass'));
const ChangePassPage = React.lazy(() => import('features/auth/pages/ChangePass'));
const UserDashboard = React.lazy(() => import('features/user/pages/UserDashboard'));
const UserList = React.lazy(() => import('features/user/pages/UserList'));
const UserAdd = React.lazy(() => import('features/user/pages/UserAdd'));
const UserEdit = React.lazy(() => import('features/user/pages/UserEdit'));
const RoleList = React.lazy(() => import('features/role/pages/RoleList'));
const ActionList = React.lazy(() => import('features/action/pages/ActionList'));
const CustomerList = React.lazy(() => import('features/customer/pages/CustomerList'));
const CustomerAdd = React.lazy(() => import('features/customer/pages/CustomerAdd'));
const CustomerEdit = React.lazy(() => import('features/customer/pages/CustomerEdit'));
const CollaboratorList = React.lazy(() => import('features/collaborator/pages/CollaboratorList'));
const CollaboratorAdd = React.lazy(() => import('features/collaborator/pages/CollaboratorAdd'));
const CollaboratorEdit = React.lazy(() => import('features/collaborator/pages/CollaboratorEdit'));
const ChatSystemList = React.lazy(() => import('features/chatSystem/pages/ChatSystemList'));
const ChatSystemAdd = React.lazy(() => import('features/chatSystem/pages/ChatSystemAdd'));
const ChatSystemEdit = React.lazy(() => import('features/chatSystem/pages/ChatSystemEdit'));
const CareerList = React.lazy(() => import('features/career/pages/CareerList'));
const JobList = React.lazy(() => import('features/job/pages/JobList'));
const JobAdd = React.lazy(() => import('features/job/pages/JobAdd'));
const JobEdit = React.lazy(() => import('features/job/pages/JobEdit'));
const InvoiceList = React.lazy(() => import('features/transaction/pages/InvoiceList'));
const ArticleList = React.lazy(() => import('features/article/pages/ArticleList'));
const ArticleAdd = React.lazy(() => import('features/article/pages/ArticleAdd'));
const ArticleEdit = React.lazy(() => import('features/article/pages/ArticleEdit'));
const UserTypeList = React.lazy(() => import('features/userType/pages/UserTypeList'));
const BankList = React.lazy(() => import('features/bank/pages/BankList'));
const BadWordList = React.lazy(() => import('features/badWord/pages/BadWordList'));
const ConfigList = React.lazy(() => import('features/config/pages/ConfigList'));

export default function RouterView() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AuthPage />}>
                    <Route index element={<LoginPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="forgot" element={<ForgotPage />} />
                    <Route path="change-password" element={<ChangePassPage />} />
                </Route>
                <Route
                    path="admin"
                    element={
                        <PrivateRoute>
                            <UserDashboard />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="user"
                    element={
                        <PrivateRoute>
                            <UserList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="user/add"
                    element={
                        <PrivateRoute>
                            <UserAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="user/edit/:id/:source"
                    element={
                        <PrivateRoute>
                            <UserEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="role"
                    element={
                        <PrivateRoute>
                            <RoleList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="action"
                    element={
                        <PrivateRoute>
                            <ActionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="customer/personal"
                    element={
                        <PrivateRoute>
                            <CustomerList isCompany={TrueFalse.FALSE} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="customer/company"
                    element={
                        <PrivateRoute>
                            <CustomerList isCompany={TrueFalse.TRUE} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="customer/personal/add"
                    element={
                        <PrivateRoute>
                            <CustomerAdd isCompany={TrueFalse.FALSE} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="customer/company/add"
                    element={
                        <PrivateRoute>
                            <CustomerAdd isCompany={TrueFalse.TRUE} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="customer/edit/:id"
                    element={
                        <PrivateRoute>
                            <CustomerEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="collaborator"
                    element={
                        <PrivateRoute>
                            <CollaboratorList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="collaborator/add"
                    element={
                        <PrivateRoute>
                            <CollaboratorAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="collaborator/edit/:id"
                    element={
                        <PrivateRoute>
                            <CollaboratorEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="chatSystem"
                    element={
                        <PrivateRoute>
                            <ChatSystemList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="chatSystem/add"
                    element={
                        <PrivateRoute>
                            <ChatSystemAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="chatSystem/edit/:id"
                    element={
                        <PrivateRoute>
                            <ChatSystemEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="career"
                    element={
                        <PrivateRoute>
                            <CareerList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="job"
                    element={
                        <PrivateRoute>
                            <JobList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="job/add"
                    element={
                        <PrivateRoute>
                            <JobAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="job/edit/:id"
                    element={
                        <PrivateRoute>
                            <JobEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="invoice/pending"
                    element={
                        <PrivateRoute>
                            <InvoiceList hasInvoice={TrueFalse.FALSE} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="invoice/done"
                    element={
                        <PrivateRoute>
                            <InvoiceList hasInvoice={TrueFalse.TRUE} />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="article"
                    element={
                        <PrivateRoute>
                            <ArticleList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="article/add"
                    element={
                        <PrivateRoute>
                            <ArticleAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="article/edit/:id"
                    element={
                        <PrivateRoute>
                            <ArticleEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="userType"
                    element={
                        <PrivateRoute>
                            <UserTypeList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="bank"
                    element={
                        <PrivateRoute>
                            <BankList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="config"
                    element={
                        <PrivateRoute>
                            <ConfigList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="badword"
                    element={
                        <PrivateRoute>
                            <BadWordList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route path="not-found" element={<NotFound />}></Route>
                <Route path="*" element={<NotFound />}></Route>
            </Routes>
        </BrowserRouter>
    );
}
