import { SearchUser } from 'features/user/types';
import User from 'types/User';
import { ItemId, ItemIds } from 'types/common';
import { ApiResponse, DataListResponse } from 'types/common/ApiResponse';
import http from './http';

const login = async (param: Partial<User>) => {
    const { data } = await http.post<ApiResponse<User>>('/user/login', param);
    return data;
};

const forgotPassword = async (email: string) => {
    const { data } = await http.post<ApiResponse<Partial<User>>>('/user/forgotPassword', { email });
    return data;
};

const changePassword = async (param: Partial<User>) => {
    const { data } = await http.post<ApiResponse<null>>('/user/changePassword', param);
    return data;
};

const logout = async () => {
    const { data } = await http.get<ApiResponse<User>>('/user/logout');
    return data;
};

const getList = async (param: SearchUser) => {
    const { data } = await http.post<ApiResponse<DataListResponse<User>>>('/user/list', param);
    return data;
};

const update = async (param: Partial<User>, id: number) => {
    if (id > 0) {
        const { data } = await http.patch<ApiResponse<User>>(`/user/${id}`, param);
        return data;
    } else {
        const { data } = await http.post<ApiResponse<User>>('/user', param);
        return data;
    }
};

const getById = async (id: number) => {
    const { data } = await http.get<ApiResponse<User>>(`/user/getById/${id}`);
    return data;
};

const updateRole = async (id: number, roleIds: ItemIds) => {
    const { data } = await http.post<ApiResponse<{}>>(`/user/updateRole/${id}`, roleIds);
    return data;
};

const sendEmailSMS = async (id: number, typeId: number) => {
    const { data } = await http.get<ApiResponse<ItemId>>(`/user/sendEmailSMS/${id}/${typeId}`);
    return data;
};

const UserService = {
    login,
    forgotPassword,
    changePassword,
    logout,
    getList,
    update,
    getById,
    updateRole,
    sendEmailSMS,
};

export default UserService;
