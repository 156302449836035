import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { COMMON_MESSAGE } from 'constants/common';
import UserService from 'services/UserService';
import User from 'types/User';
export interface AuthState {
    user: User | null;
    isLoading: boolean;
    messages: string[];
}

const initialState: AuthState = {
    user: null,
    isLoading: false,
    messages: [],
};

export const login = createAsyncThunk('user/login', async (data: Partial<User>) => UserService.login(data));

export const logout = createAsyncThunk('user/logout', async () => UserService.logout());

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                const apiResponse = action.payload;
                if (apiResponse.success) state.user = apiResponse.data;
                else state.messages = apiResponse.messages;
            })
            .addCase(login.rejected, (state) => {
                state.isLoading = false;
                state.messages = [COMMON_MESSAGE.ERROR_MESSAGE as string];
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoading = false;
                state.user = null;
                state.messages = [];
            });
    },
});

export const { setUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
