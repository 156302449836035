import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RouterView from 'routes';

function App() {
    return (
        <>
            <RouterView />
            <ToastContainer autoClose={2000} />
        </>
    );
}

export default App;
