import { SelectOption } from 'types/common';

export enum COMMON_MESSAGE {
    ERROR_MESSAGE = 'Có lỗi xảy ra trong quá trình thực hiện',
    EXPIRED_TOKEN = 'Phiên làm việc của bạn đã hết hạn, vui lòng đăng nhập lại',
    FIELD_REQUIRED = 'Trường này không được bỏ trống',
    FIELD_PASSWORD_LENGTH = 'Mật khẩu ít nhất 6 ký tự',
    FIELD_PASSWORD_MATCH = 'Mật khẩu không giống nhau',
    FIELD_EMAIL = 'Email không hợp lệ',
    FIELD_PHONE = 'Số điện thoại không hợp lệ',
    FIELD_NUMBER = 'Trường này phải là số',
    MIN_NUMBER = 'Giá trị bé nhất là ',
    MAX_NUMBER = 'Giá trị lớn nhất nhất là ',
    FIELD_DATE = 'Ngày tháng không hợp lệ',
}

export enum DEFAULT_VALUE {
    IMAGE = '/assets/images/avatars/default.jpg',
}

export const PAGINATION = {
    countItem: 0,
    totalPage: 1,
    currentPage: 1,
    limit: 10,
};

export enum STATUS {
    ALL = 0,
    INACTIVE = 1,
    ACTIVE = 2,
}

export const LABEL_CSS = [
    {
        id: 1,
        name: 'badge badge-glow bg-danger',
    },
    {
        id: 2,
        name: 'badge badge-glow bg-success',
    },
    {
        id: 3,
        name: 'badge badge-glow bg-warning',
    },
    {
        id: 4,
        name: 'badge badge-glow bg-primary',
    },
    {
        id: 5,
        name: 'badge badge-glow bg-secondary',
    },
    {
        id: 6,
        name: 'badge badge-glow bg-info',
    },
    {
        id: 7,
        name: 'badge badge-glow bg-dark',
    },
];

export const optionSelectDefault: SelectOption = { value: 0, label: '--' };
