export interface ItemParam {
    id: number;
    name: string;
}

export interface ItemCount extends ItemParam {
    count: number;
}

export interface ItemLink {
    id?: number;
    text: string;
    to: string;
    icon?: 'PLUS' | 'DOWNLOAD' | 'SEND';
    fnCallBack?: {
        actionMenu: (id: number) => void;
    };
}

export interface ItemId {
    id: number;
}

export interface ItemIds {
    ids: number[] | string[];
}

export interface ItemChecked {
    id: number;
    checked: boolean;
}

export interface ItemFile {
    id?: number;
    name: string;
    url: string;
    mineType?: string;
    fileSize?: number;
}

export interface Url {
    url: string;
}

export enum TrueFalse {
    ALL = 0,
    FALSE = 1,
    TRUE = 2,
}

export enum HierarchyReturnType {
    LIST = 1,
    PARENT = 2,
}

export enum Language {
    VI = 'vi',
    EN = 'en',
}

export interface SelectOption {
    value: number;
    label: string;
}
