import { COMMON_MESSAGE, LABEL_CSS } from 'constants/common';
import { find, get } from 'lodash';
import { toast, ToastOptions } from 'react-toastify';
import { ItemParam, SelectOption } from 'types/common';

export const showToast = (success: boolean, messages?: string[]) => {
    const options: ToastOptions<{}> = {
        position: toast.POSITION.TOP_RIGHT,
        toastId: Math.random(),
    };
    let MsgNode = null;
    if (messages && messages.length > 1) {
        MsgNode = (
            <div>
                {messages.map((message: string, index: number) => (
                    <p key={index}>{message}</p>
                ))}
            </div>
        );
    }
    if (success) {
        if (messages && messages.length > 0) {
            if (messages.length === 1) {
                toast.success(messages[0], options);
            } else {
                toast.success(MsgNode, options);
            }
        }
    } else {
        if (messages && messages.length > 0) {
            if (messages.length === 1) {
                toast.error(messages[0], options);
            } else {
                toast.error(MsgNode, options);
            }
        } else {
            toast.error(COMMON_MESSAGE.ERROR_MESSAGE, options);
        }
    }
};

export const genTableIndex = (index: number, limit: number, currentPage: number) =>
    index + limit * (currentPage - 1) + 1;

export const getFieldHtml = (fields: ItemParam[], itemId: number) => (
    <span className={getFieldInArrayObject(LABEL_CSS, itemId)}>{getFieldInArrayObject(fields, itemId)}</span>
);

export const getFieldInArrayObject = (listObj: object[], fieldId: number) =>
    get(find(listObj, ['id', fieldId]), 'name');

export const toggleModalOpen = (show: boolean) => {
    if (show) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }
};

export const closeModal = (changeShow: Function) => {
    if (changeShow) {
        changeShow(false);
    }
};

export const selectItem = (listItems: ItemParam[], noNoneOption?: boolean) => {
    const selectOptions: JSX.Element[] = [];
    if (!noNoneOption) {
        selectOptions.push(
            <option key={0} value={0}>
                --
            </option>
        );
    }
    listItems.forEach((item) => {
        selectOptions.push(
            <option key={item.id} value={item.id}>
                {item.name}
            </option>
        );
    });
    return selectOptions;
};

export const convertToSelectOptions = (items: ItemParam[], noNoneOption?: boolean) => {
    const selectOptions: SelectOption[] = [];
    if (!noNoneOption) {
        selectOptions.push({
            value: 0,
            label: '',
        });
    }
    items.forEach((item) => {
        selectOptions.push({
            value: item.id,
            label: item.name,
        });
    });
    return selectOptions;
};
