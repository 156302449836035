import http from './http';
import { ApiResponse, DataListResponse } from 'types/common/ApiResponse';
import Action from 'types/Action';
import { STATUS } from 'constants/common';
import { HierarchyReturnType, ItemIds } from 'types/common';

const getList = async (statusId: STATUS, type: HierarchyReturnType, userId?: number, roleId?: number) => {
    const { data } = await http.post<ApiResponse<DataListResponse<Action>>>('/action/list/' + type, {
        userId,
        roleId,
        statusId,
    });
    return data;
};

const getIdsByUser = async (userId: number) => {
    const { data } = await http.get<ApiResponse<ItemIds>>('/action/user/' + userId);
    return data;
};

const update = async (param: Partial<Action>, id: number) => {
    if (id > 0) {
        const { data } = await http.patch<ApiResponse<Action>>(`/action/${id}`, param);
        return data;
    } else {
        const { data } = await http.post<ApiResponse<Action>>('/action', param);
        return data;
    }
};

const ActionService = {
    getList,
    getIdsByUser,
    update,
};

export default ActionService;
