import axios from 'axios';
import { AUTH_KEYS } from 'constants/auth';
import jwt_decode from 'jwt-decode';
import { includes } from 'lodash';
import { Language } from 'types/common';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils';

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-type': 'application/json',
    },
});

http.interceptors.request.use(
    async function (config) {
        let accessToken = getLocalStorage(
            config.url === '/user/logout' ? AUTH_KEYS.REFRESH_TOKEN : AUTH_KEYS.ACCESS_TOKEN
        );
        if (!includes(['/user/login', '/user/forgotPassword', '/user/changePassword', '/user/logout'], config.url)) {
            // @ts-ignore
            const { exp } = jwt_decode(accessToken);
            if (exp && Date.now() > exp * 1000) {
                await getToken((act: string) => {
                    if (act) accessToken = act;
                });
            }
        }
        config.headers!.Authorization = accessToken ? `Bearer ${accessToken}` : '';
        config.headers!['lang-code'] = getLocalStorage(AUTH_KEYS.LANGUAGE_CODE, Language.VI);
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    async function (response) {
        if (response.data.status === 401) {
            await getToken(() => {
                location.reload();
            });
        } else return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const getToken = async (fnSuccess: Function) => {
    const refreshToken = getLocalStorage(AUTH_KEYS.REFRESH_TOKEN);
    const res = await axios.get(process.env.REACT_APP_API_URL + '/user/refreshToken', {
        headers: {
            'Content-type': 'application/json',
            Authorization: refreshToken ? `Bearer ${refreshToken}` : '',
        },
    });
    let isSuccess = false;
    if (res.status === 200) {
        if (res.data.data.accessToken && res.data.data.refreshToken) {
            isSuccess = true;
            setLocalStorage(AUTH_KEYS.ACCESS_TOKEN, res.data.data.accessToken);
            setLocalStorage(AUTH_KEYS.REFRESH_TOKEN, res.data.data.refreshToken);
            fnSuccess(res.data.data.accessToken);
        }
    }
    if (!isSuccess) {
        window.location.href = '/';
        removeLocalStorage(AUTH_KEYS.ACCESS_TOKEN);
        removeLocalStorage(AUTH_KEYS.REFRESH_TOKEN);
    }
};

export default http;
