import { useLayoutEffect } from 'react';
import './page-misc.css';

export function NotFound() {
    useLayoutEffect(() => {
        document.body.classList.add('blank-page');
    }, []);
    return (
        <div className="app-content content ">
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <div className="content-wrapper">
                <div className="content-header row"></div>
                <div className="content-body">
                    <div className="misc-wrapper">
                        <div className="misc-inner p-2 p-sm-3">
                            <div className="w-100 text-center">
                                <h2 className="mb-1">Page Not Found 🕵🏻‍♀️</h2>
                                <p className="mb-2">Oops! 😖 The requested URL was not found on this server.</p>
                                <a className="btn btn-primary mb-2 btn-sm-block" href="/">
                                    Back to home
                                </a>
                                <img className="img-fluid" src="/assets/images/pages/error.svg" alt="Error page" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
