import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface LanguageState {
    langCode: string;
}

const initialState: LanguageState = {
    langCode: 'vi',
};

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLangCode: (state, action) => {
            state.langCode = action.payload;
        },
    },
});

export const { setLangCode } = languageSlice.actions;

export const selectLangCode = (state: RootState) => state.language.langCode;

export default languageSlice.reducer;
